import {
  CheckCircle,
  Delete,
  Edit,
  Error,
  Image,
  Upload,
} from "@mui/icons-material";
import JSZip from "jszip";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import MaterialReactTable, {
  MaterialReactTableProps,
  MRT_Cell,
  MRT_ColumnDef,
  MRT_Row,
} from "material-react-table";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { FC, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { useCSVDownloader, useCSVReader } from "react-papaparse";
import axiosClient from "../../api/axiosClient";
import { useAuth } from "../../contexts/AuthContext";
import {
  categoriesList,
  colorsList,
  countries,
  compositionList,
  designsList,
  fiberTypes,
  materialsList,
  currencies,
} from "../../utils/FilterEngine";
import { UploadProduct } from "./UploadProduct";
import imageCompression from "browser-image-compression";

const columnsName = [
  "title",
  "description",
  "type",
  "color",
  "material",
  "pattern",
  "length",
  "composition",
  "fiber",
  "weight",
  "width",
  "price",
  "uses",
  "designs",
  "category",
  "images",
];

const SKUTable: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { CSVReader } = useCSVReader();
  const { CSVDownloader } = useCSVDownloader();
  const { organisation, globalFilters } = useAuth();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState<SKU[] | any>([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [delimiter, setDelimiter] = useState(";");
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const [returnListSuccess, setReturnListSuccess] = useState<any[]>([]);
  const [returnListError, setReturnListError] = useState<any[]>([]);
  const [imagesAdded, setImagesAdded] = useState(false);
  const [images, setImages] = useState<any[]>([]);
  const confirm = useConfirm();

  // Filter empty values from tableData rows
  const filterTable = async (data: SKU[]) => {
    data.forEach((row: SKU) => {
      Object.keys(row).forEach((k: string) => {
        if (row[k as keyof SKU] === "") {
          delete row[k as keyof SKU];
        }
      });
    });

    return data;
  };

  const handleDelimiterChange = (event: SelectChangeEvent) => {
    setDelimiter(event.target.value);
  };

  const handleCreateNewRow = (values: SKU) => {
    tableData.push(values);
    setTableData([...tableData]);
  };

  const handleSaveRowEdits: MaterialReactTableProps<SKU>["onEditingRowSave"] =
    async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        tableData[row.index] = values;

        //send/receive api updates here, then refetch or update local table data for re-render
        setTableData([...tableData]);
        exitEditingMode(); //required to exit editing mode and close modal
      }
    };

  // Bulk update all data in tableData
  const handleBulkUpdate = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const data = await filterTable(tableData);
    UpdateSKU(data);
  };

  // Bulk update all data in tableData
  const UpdateSKU = async (data: any) => {
    setIsUpdating(true);
    const resultArray: any[] = [];

    // Reset return lists
    setReturnListError([]);
    setReturnListSuccess([]);

    // UI notification of update
    enqueueSnackbar(`Updating stock ...`, {
      variant: "success",
      preventDuplicate: true,
    });

    // Await all promises
    await Promise.all(
      // Map through data and update each product
      data.map(async (product: any) => {
        const data = {
          title: product.Title,
          options: {
            price: product.Price,
            length: product.Length,
          },
        };

        await axiosClient
          .put(`api/organisation/${organisation}/listing/update/bulk`, data)
          .then((res) => {
            resultArray.push({
              status: "success",
              title: product.Title,
            });
          })
          .catch((err) => {
            resultArray.push({
              status: "error",
              title: product.Title,
              error: err.message,
            });
          });
      })
    );

    // UI notification of update
    setReturnListSuccess(
      resultArray.filter((item: any) => item.status === "success")
    );
    setReturnListError(
      resultArray.filter((item: any) => item.status === "error")
    );
    setIsUpdating(false);
  };

  const handleDeleteRow = useCallback(
    (row: MRT_Row<SKU>) => {
      confirm({
        description: `Are you sure you want to delete ${row.getValue(
          "Title"
        )}?`,
        //send api delete request here, then refetch or update local table data for re-render
      }).then(() => {
        tableData.splice(row.index, 1);
        setTableData([...tableData]);
      });
    },
    [tableData]
  );

  const getCommonEditTextFieldProps = useCallback(
    (
      cell: MRT_Cell<SKU>
    ): MRT_ColumnDef<SKU>["muiTableBodyCellEditTextFieldProps"] => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === "email"
              ? validateEmail(event.target.value)
              : cell.column.id === "age"
              ? validateAge(+event.target.value)
              : validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors]
  );

  /**
   * Convert to base64
   *
   * @description Converts a blob to base64
   * @param {Blob} data - image data
   * @returns {Promise<String>} - base64 string
   *
   */
  const convertToBase64 = async (data: Blob): Promise<String> => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(data);
    });
  };

  /**
   * Upload zip
   *
   * @description Handles uploading a zipped folder of images
   * @param e - event
   *
   */
  const uploadZip = async (e: any) => {
    // Check if multiple files
    if (e.target.files.length > 1) {
      enqueueSnackbar(`Please upload only one file`, {
        variant: "error",
        preventDuplicate: true,
      });
      return;
    } else {
      // If only one file
      const fileArchive = e.target.files[0];

      // Check file extension
      if (fileArchive.name.split(".").pop() !== "zip") {
        enqueueSnackbar(`Please upload a zip file`, {
          variant: "error",
          preventDuplicate: true,
        });
        return;
      } else {
        // Extract zip
        const zip = new JSZip();
        await zip.loadAsync(fileArchive).then(async (contents) => {
          const promises: Promise<void>[] = [];
          contents.forEach((relativePath, file) => {
            if (!file.dir) {
              // check if it's a file and not a directory
              const extension = relativePath.split(".").pop();

              // Check if file is an image
              if (extension && !extension.match(/(jpg|jpeg|png|webp)$/i)) {
                enqueueSnackbar(`Please upload only images`, {
                  variant: "error",
                  preventDuplicate: true,
                });
                return;
              } else {
                // If file is an image
                const promise = file.async("blob").then(async (data) => {
                  // Read the file data as a blob
                  // Convert to base64
                  const base64: String = await convertToBase64(data);

                  // Add to images array
                  setImages((prevImages) => [
                    ...prevImages,
                    {
                      title: relativePath.split(".")[0],
                      fileName: "image 0",
                      fileContentBase64: base64.split(",")[1],
                    },
                  ]);
                });
                promises.push(promise);
              }
            }
          });
          await Promise.all(promises);

          enqueueSnackbar(`Images successfully loaded`, {
            variant: "success",
            preventDuplicate: true,
          });
          setImagesAdded(true);
        });
      }
    }
  };

  /**
   * Check for missing fields
   */
  const checkMissingField = (data: any) => {
    const fields = [
      "title",
      "description",
      "type",
      "category",
      "options.colors",
      "options.length",
      "options.weight",
      "options.price",
      "options.width",
      "options.countryCode",
      "options.fibers",
      "options.compositions",
      "options.materials",
      "options.designs",
      "options.usages",
      "images",
    ];

    for (let field of fields) {
      let value = data;
      for (let key of field.split(".")) {
        value = value[key];
        if (!value) {
          return field;
        }
      }
    }

    return null;
  };

  /**
   * Handle bulk upload.
   */
  const handleBulkUpload = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const resultArray: any[] = [];

    // UI notification of update
    enqueueSnackbar(`Uploading listings ...`, {
      variant: "success",
      preventDuplicate: true,
    });

    // Reset return lists
    setReturnListError([]);
    setReturnListSuccess([]);

    // Set updating state
    setIsUpdating(true);

    // Await all promisesd
    await Promise.all(
      tableData.map(async (product: any, index: number) => {
        const image = images.find(
          (image: any) => image.title === product.Title
        );

        if (!image) {
          // Add product to error list
          resultArray.push({
            status: "error",
            title: product.Title,
            error: "No image found",
          });
          return; // Skip this product
        }

        // Create data object
        const data = {
          title: product.Title,
          description: product.Description,
          type: product.Type,
          category: product.Category,
          options: {
            colors: product.Colors.includes(",")
              ? product.Colors.split(",")
              : [product.Colors],
            length: product.Length,
            weight: product.Weight,
            price: product.Price,
            width: product.Width,
            countryCode: "NL",
            currency: product.Currency,
            usages: product.Usages.includes(",")
              ? product.Usages.split(",")
              : [product.Usages],
            fibers: product.Fibers.includes(",")
              ? product.Fibers.split(",")
              : [product.Fibers],
            compositions: product.Compositions.includes(",")
              ? product.Compositions.split(",")
              : [product.Compositions],
            materials: product.Materials.includes(",")
              ? product.Materials.split(",")
              : [product.Materials],
            designs: product.Designs.includes(",")
              ? product.Designs.split(",")
              : [product.Designs],
          },
          images: [image],
        };

        // return error if any field is empty
        const missingField = checkMissingField(data);
        if (missingField) {
          resultArray.push({
            status: "error",
            title: product.Title,
            error: `Missing field: ${missingField}`,
          });
          return;
        }

        // Send request
        await axiosClient
          .post(`api/organisation/${organisation}/listing/create`, data)
          .then((res) => {
            resultArray.push({
              status: "success",
              title: product.Title,
            });
          })
          .catch((err) => {
            resultArray.push({
              status: "error",
              title: product.Title,
              error: err.message,
            });
          });
      })
    );

    // UI notification of update
    setReturnListSuccess(
      resultArray.filter((item: any) => item.status === "success")
    );
    setReturnListError(
      resultArray.filter((item: any) => item.status === "error")
    );
    setIsUpdating(false);
  };

  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<SKU>[]>(
    () => [
      {
        accessorKey: "Title",
        header: "Title",
      },
      {
        accessorKey: "Description",
        header: "Description",
        muiTableBodyCellEditTextFieldProps: {
          multiline: true,
        },
      },
      {
        accessorKey: "Type",
        header: "Type",
      },
      {
        accessorKey: "Category",
        header: "Category",
        muiTableBodyCellEditTextFieldProps: {
          select: true, //change to select for a dropdown
          children: globalFilters!.categories.map((category: any) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          )),
        },
      },
      {
        accessorKey: "Colors",
        header: "Colors",
        Edit: ({ cell, column, row, table }) => (
          <Autocomplete
            multiple
            defaultValue={[...cell.getValue<string>().split(",")]}
            onChange={(event: any, newValue: string[]) => {
              table.setEditingRow({
                ...row,

                _valuesCache: {
                  ...row._valuesCache,
                  [column.id]: newValue!.join(","),
                },
              });
            }}
            renderInput={(params) => <TextField {...params} label="Colors" />}
            options={globalFilters.colors}
          />
        ),
      },
      {
        accessorKey: "Materials",
        header: "Materials",
        Edit: ({ cell, column, row, table }) => (
          <Autocomplete
            multiple
            defaultValue={[...cell.getValue<string>().split(",")]}
            onChange={(event: any, newValue: string[]) => {
              table.setEditingRow({
                ...row,
                _valuesCache: {
                  ...row._valuesCache,
                  [column.id]: newValue!.join(","),
                },
              });
            }}
            renderInput={(params) => (
              <TextField {...params} label="Materials" />
            )}
            options={globalFilters.materials}
          />
        ),
      },
      {
        accessorKey: "Compositions",
        header: "Compositions",
        Edit: ({ cell, column, table, row }) => (
          <Autocomplete
            multiple
            defaultValue={[...cell.getValue<string>().split(",")]}
            onChange={(event: any, newValue: string[]) => {
              table.setEditingRow({
                ...row,
                _valuesCache: {
                  ...row._valuesCache,
                  [column.id]: newValue.join(","),
                },
              });
            }}
            renderInput={(params) => (
              <TextField {...params} label="Composition" />
            )}
            options={globalFilters.compositions}
          />
        ),
      },
      {
        accessorKey: "Usages",
        header: "Usages",
        Edit: ({ cell, column, table, row }) => (
          <Autocomplete
            multiple
            defaultValue={[...cell.getValue<string>().split(",")]}
            onChange={(event: any, newValue: string[]) => {
              table.setEditingRow({
                ...row,
                _valuesCache: {
                  ...row._valuesCache,
                  [column.id]: newValue.join(","),
                },
              });
            }}
            renderInput={(params) => <TextField {...params} label="Usages" />}
            options={globalFilters.usages}
          />
        ),
      },
      {
        accessorKey: "Fibers",
        header: "Fibers",
        Edit: ({ cell, column, table, row }) => (
          <Autocomplete
            multiple
            defaultValue={[...cell.getValue<string>().split(",")]}
            onChange={(event: any, newValue: string[]) => {
              table.setEditingRow({
                ...row,
                _valuesCache: {
                  ...row._valuesCache,
                  [column.id]: newValue.join(","),
                },
              });
            }}
            renderInput={(params) => <TextField {...params} label="Fibers" />}
            options={globalFilters.fibers}
          />
        ),
      },
      {
        accessorKey: "Designs",
        header: "Designs",
        Edit: ({ cell, column, table, row }) => (
          <Autocomplete
            multiple
            defaultValue={[...cell.getValue<string>().split(",")]}
            onChange={(event: any, newValue: string[]) => {
              table.setEditingRow({
                ...row,
                _valuesCache: {
                  ...row._valuesCache,
                  [column.id]: newValue.join(","),
                },
              });
            }}
            renderInput={(params) => <TextField {...params} label="Designs" />}
            options={globalFilters!.designs}
          />
        ),
      },
      {
        accessorKey: "Length",
        header: "Length (m)",
      },
      {
        accessorKey: "Width",
        header: "Width (m)",
      },
      {
        accessorKey: "Weight",
        header: "Weight (g/m2)",
      },
      {
        accessorKey: "CountryCode",
        header: "Country of Origin",
      },
      {
        accessorKey: "Currency",
        header: "Currency",
        muiTableBodyCellEditTextFieldProps: {
          select: true, //change to select for a dropdown
          children: currencies.map((currency: any) => (
            <MenuItem key={currency.value} value={currency.value}>
              {currency.label}
            </MenuItem>
          )),
        },
      },
      {
        accessorKey: "Price",
        header: "Price",
      },
    ],

    [getCommonEditTextFieldProps]
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <MaterialReactTable
          displayColumnDefOptions={{
            "mrt-row-actions": {
              muiTableHeadCellProps: {
                align: "center",
              },
              size: 120,
            },
          }}
          muiTablePaperProps={{ sx: { width: "95vw" } }}
          muiToolbarAlertBannerProps={{
            sx: { background: "#c7c1ba", color: "#fff" },
          }}
          initialState={{ columnVisibility: { id: false, createdAt: false } }}
          columns={columns}
          data={tableData}
          editingMode="modal" //default
          enableColumnOrdering
          enableEditing
          onEditingRowSave={handleSaveRowEdits}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              {
                <Suspense fallback={<div>Loading...</div>}>
                  <UploadProduct
                    product={row.original}
                    row={row}
                    tableData={tableData}
                    setTableData={setTableData}
                  />
                </Suspense>
              }
              <Tooltip arrow placement="left" title="Edit">
                <IconButton onClick={() => table.setEditingRow(row)}>
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          renderTopToolbarCustomActions={({ table }) => {
            // const handleDelete = () => {
            //   table.getSelectedRowModel().flatRows.map((row) => {
            //     alert("deactivating " + row.original.Title);
            //   });
            // };

            return (
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignContent="center"
              >
                <Tooltip arrow placement="top" title="Create a new product row">
                  <Button
                    color="primary"
                    onClick={() => setCreateModalOpen(true)}
                    variant="contained"
                  >
                    Add product
                  </Button>
                </Tooltip>
                <CSVReader
                  onUploadAccepted={(results: any) => {
                    setTableData(results.data);
                  }}
                  config={{ header: true, delimiter: delimiter }}
                >
                  {({
                    getRootProps,
                    acceptedFile,
                    ProgressBar,
                    getRemoveFileProps,
                  }: any) => (
                    <>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Tooltip
                          arrow
                          placement="top"
                          title="Import rows from a .csv file"
                        >
                          <Button
                            sx={{ height: "100%" }}
                            type="button"
                            variant="contained"
                            color="primary"
                            {...getRootProps()}
                          >
                            Bulk add products (CSV)
                          </Button>
                        </Tooltip>
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 120 }}
                        >
                          <InputLabel id="demo-simple-select-standard-label">
                            Delimiter
                          </InputLabel>
                          <Select
                            labelId="-select-delimiter"
                            id="delimiter-select"
                            value={delimiter}
                            onChange={handleDelimiterChange}
                            label="Delimiter"
                          >
                            <MenuItem value={";"}>Semicolon</MenuItem>
                            <MenuItem value={","}>Comma</MenuItem>
                          </Select>
                        </FormControl>

                        {/* <Box>{acceptedFile && acceptedFile.name}</Box> */}
                      </Stack>
                      {/* <ProgressBar style={styles.progressBarBackgroundColor} /> */}
                    </>
                  )}
                </CSVReader>
                <CSVDownloader
                  filename={"filename"}
                  bom={true}
                  data={filterTable(tableData)}
                >
                  <Button
                    sx={{ height: "100%" }}
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => filterTable(tableData)}
                  >
                    Export All Data
                  </Button>
                </CSVDownloader>
                {/* <Tooltip
                  arrow
                  placement="top"
                  title="Update price and length for all rows"
                >
                  <Button
                    disabled={
                      tableData.length === 0 || isUpdating || imagesAdded
                    }
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={(e) => handleBulkUpdate(e)}
                  >
                    <Upload />
                    Update existing stock
                  </Button>
                </Tooltip> */}
                <Tooltip
                  arrow
                  placement="top"
                  title="Upload a .zip folder with images named to correspond with the product title"
                >
                  <Button variant="contained" color="primary" component="label">
                    <Image /> Add images
                    <input
                      id="upload"
                      type="file"
                      accept=".zip"
                      hidden
                      onChange={(e) => uploadZip(e)}
                    />
                  </Button>
                </Tooltip>
                <Tooltip
                  arrow
                  placement="top"
                  title="Upload all rows as new products"
                >
                  <Button
                    disabled={!imagesAdded || isUpdating}
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={(e) => handleBulkUpload(e)}
                  >
                    <Upload /> Bulk-upload new products
                  </Button>
                </Tooltip>
              </Stack>
            );
          }}
        />
        <CreateNewProductModal
          columns={columns}
          open={createModalOpen}
          onClose={() => setCreateModalOpen(false)}
          onSubmit={handleCreateNewRow}
        />
      </Box>

      {/* Return success/error list if data is returned */}
      {(returnListError.length > 0 ||
        returnListSuccess.length > 0 ||
        (returnListError.length > 0 && returnListSuccess.length > 0)) && (
        <Card sx={{ minWidth: { xs: "95vw", md: 650 } }}>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <List sx={{ minWidth: { md: 325 } }}>
              <ListItem>
                <ListItemIcon>
                  <CheckCircle color="primary" />
                </ListItemIcon>
                <ListItemText primary={"Success"} />
              </ListItem>
              <Divider />
              {returnListSuccess.map(
                (
                  item: any // Map through success list
                ) => (
                  <div key={item.title}>
                    <ListItem>
                      <ListItemText primary={item.title} />
                    </ListItem>
                    <Divider light />
                  </div>
                )
              )}
            </List>
            <List sx={{ minWidth: { md: 325 } }}>
              <ListItem>
                <ListItemIcon>
                  <Error color="error" />
                </ListItemIcon>
                <ListItemText primary={"Error"} />
              </ListItem>
              <Divider />
              {returnListError.map(
                (
                  item: any // Map through error list
                ) => (
                  <div key={item.title}>
                    <ListItem>
                      <ListItemText
                        primary={item.title}
                        secondary={item.error}
                      />
                    </ListItem>
                    <Divider light />
                  </div>
                )
              )}
            </List>
          </Stack>
        </Card>
      )}
    </>
  );
};

//example of creating a mui dialog modal for creating new rows
//TODO: Remodel/Formik/Change Design
export const CreateNewProductModal: FC<{
  columns: MRT_ColumnDef<SKU>[];
  onClose: () => void;
  onSubmit: (values: SKU) => void;
  open: boolean;
}> = ({ open, columns, onClose, onSubmit }) => {
  const [values, setValues] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ""] = "";
      return acc;
    }, {} as any)
  );

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values);
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Create New SKU</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            {columns.map((column) => (
              <TextField
                key={column.accessorKey}
                label={column.header}
                name={column.accessorKey}
                onChange={(e) => {
                  e.target.value !== "" &&
                    setValues({ ...values, [e.target.name]: e.target.value });
                }}
              />
            ))}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit} variant="contained">
          Create New SKU
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const validateRequired = (value: string) => !!value.length;
const validateEmail = (email: string) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
const validateAge = (age: number) => age >= 18 && age <= 50;

export default SKUTable;
