import { MoreVert } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  ListItem,
  Menu,
  Stack,
  Toolbar,
} from "@mui/material";
import Image from "mui-image";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import logo from "../../assets/logo/logo-black.png";
import logoMobile from "../../assets/logo/logo-mobile-dark.png";
import { useAuth } from "../../contexts/AuthContext";
import Login from "../Auth/Login";
import { Search, StyledInputBase } from "../Text/Elements";
import LinkText from "../LinkText";

const Navbar = () => {
  const { t } = useTranslation();
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState<null | HTMLElement>(
    null
  );

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchTerm(e.target.value);

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setMobileMenuAnchor(event.currentTarget);
  const handleMobileMenuClose = () => setMobileMenuAnchor(null);

  const submitSearch = (event: any) => {
    event.preventDefault();
    if (searchTerm) {
      search.set("query", searchTerm);
    } else {
      search.delete("query");
    }
    setSearch(search, { replace: true });
    navigate(`/search-page${searchTerm ? `?query=${searchTerm}` : ""}`);
  };

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMenuAnchor}
      open={Boolean(mobileMenuAnchor)}
      onClose={handleMobileMenuClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      {["recycled", "deadstock", "clean"].map((type) => (
        <ListItem key={type}>
          <LinkText href={`/search-page?type=${type}`}>{`${
            type.charAt(0).toUpperCase() + type.slice(1)
          } fabric`}</LinkText>
        </ListItem>
      ))}
      <Divider />
      <ListItem>
        <LinkText href="/how-it-works">How it works</LinkText>
      </ListItem>
      <Divider />
      <ListItem>
        <LinkText href="/about-us">About us</LinkText>
      </ListItem>
      <Divider />
      <ListItem>
        <Login variant="black" />
      </ListItem>
    </Menu>
  );

  return (
    <>
      <AppBar
        elevation={0}
        sx={{ borderBottom: "0px solid #dfe0df", py: "2em" }}
      >
        <Toolbar>
          <Stack
            alignItems="center"
            justifyContent="space-around"
            direction="row"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <Box sx={{ display: { xs: "inline-block", md: "none" } }}>
              <Link to="/">
                <Image src={logoMobile} height={40} width={40} fit="contain" />
              </Link>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "inline-block" },
                marginLeft: "-16px",
              }}
            >
              <Link to="/">
                <Image src={logo} height={40} width={200} fit="contain" />
              </Link>
            </Box>

            <Stack direction="row" alignItems="center" sx={{ flexGrow: 1 }}>
              <Stack
                sx={{
                  flexGrow: 1,
                  border: "1px solid black",
                  borderRadius: "20px",
                  paddingRight: "0.5em",
                  marginX: { xs: "1rem", sm: "2rem" },
                }}
                justifyContent="center"
                alignItems="center"
                direction="row"
                spacing={0.1}
              >
                <form onSubmit={submitSearch} style={{ width: "100%" }}>
                  <Search direction="row-reverse" sx={{ padding: "10px" }}>
                    <IconButton type="submit">
                      <SearchIcon
                        color="inherit"
                        sx={{ stroke: "black", strokeWidth: 1, color: "black" }}
                      />
                    </IconButton>
                    <StyledInputBase
                      onChange={onSearchChange}
                      placeholder={t("Search products")}
                      inputProps={{ "aria-label": "search" }}
                      sx={{
                        flex: 1,
                        "& input::placeholder": {
                          color: "black",
                          opacity: 1,
                        },
                      }}
                    />
                  </Search>
                </form>
              </Stack>

              <Stack
                direction="row"
                spacing={1}
                sx={{ display: { xs: "none", lg: "flex" } }}
              >
                {["recycled", "deadstock", "clean"].map((type) => (
                  <LinkText key={type} href={`/search-page?type=${type}`}>{`${
                    type.charAt(0).toUpperCase() + type.slice(1)
                  } fabric`}</LinkText>
                ))}
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    borderColor: "black",
                    height: "30px",
                    alignSelf: "center",
                    borderWidth: 1,
                  }}
                />
                <LinkText href="/how-it-works">How it works</LinkText>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    borderColor: "black",
                    height: "30px",
                    alignSelf: "center",
                    borderWidth: 1,
                  }}
                />
                <LinkText href="/about-us">About us</LinkText>
                <Divider />
                <Login variant="black" />
              </Stack>

              <Box
                sx={{
                  display: { xs: "flex", lg: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  aria-label="show more"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreVert sx={{ fontSize: 35, color: "black" }} />
                </IconButton>
              </Box>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </>
  );
};

export default Navbar;
