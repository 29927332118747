import {
  AccountCircle,
  Favorite,
  Logout,
  Mail,
  ShoppingCart,
} from "@mui/icons-material";
import MoreVert from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Badge,
  Box,
  Divider,
  IconButton,
  ListItem,
  Stack,
  Toolbar,
  Tooltip,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Image from "mui-image";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import logo from "../../assets/logo/logo-black.png";
import logoMobile from "../../assets/logo/logo-mobile-dark.png";
import { useAuth } from "../../contexts/AuthContext";
import { Search, StyledInputBase } from "../Text/Elements";
import LinkText from "../LinkText";
import { AddProduct } from "../Forms/AddProduct";
import CreateOrganisation from "../Forms/CreateOrganisation";

const UserNavbar = () => {
  const { t } = useTranslation();
  const { auth, setAuth, organisation, logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);

  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>(
    search.get("query") ?? ""
  );

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const submitSearch = (event: any) => {
    event.preventDefault();
    if (searchTerm.length === 0) {
      search.delete("query");
      setSearch(search, { replace: true });
      navigate(`/search-page`);
    } else {
      search.set("query", searchTerm);
      setSearch(search, { replace: true });
      navigate(`/search-page?query=${searchTerm}`);
    }
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);
  const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setMobileMoreAnchorEl(event.currentTarget);

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => navigate("my-account")}>
        <AccountCircle fontSize="small" sx={{ marginX: 1 }} />
        {t("My account.1")}
      </MenuItem>
      <MenuItem onClick={() => navigate("my-shop")}>
        <ShoppingCart fontSize="small" sx={{ marginX: 1 }} />
        {t("My Shop.1")}
      </MenuItem>
      <MenuItem
        onClick={() => {
          logout();
          navigate("/");
        }}
        sx={{ marginX: 1 }}
      >
        <Logout fontSize="small" sx={{ marginX: 1 }} />
        {t("Logout.1")}
      </MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={handleMobileMenuClose}
    >
      {["recycled", "deadstock", "clean"].map((type) => (
        <ListItem key={type}>
          <LinkText href={`/search-page?type=${type}`}>{`${
            type.charAt(0).toUpperCase() + type.slice(1)
          } fabric`}</LinkText>
        </ListItem>
      ))}
      <Divider />
      <ListItem>
        <LinkText href="/about-us">About us</LinkText>
      </ListItem>
      <Divider />
      <MenuItem onClick={() => navigate("my-account")} sx={{ marginTop: 1 }}>
        <AccountCircle fontSize="medium" sx={{ marginX: 1 }} />
        {t("My account.1")}
      </MenuItem>
      {organisation && (
        <>
          <MenuItem onClick={() => navigate("my-shop")} sx={{ marginTop: 1 }}>
            <ShoppingCart fontSize="medium" sx={{ marginX: 1 }} />
            {t("My Shop.1")}
          </MenuItem>
          <MenuItem
            onClick={() => navigate("my-favourites")}
            sx={{ marginTop: 1 }}
          >
            <Favorite fontSize="medium" sx={{ marginX: 1 }} />
            {t("My favorites")}
          </MenuItem>
        </>
      )}
      <MenuItem
        onClick={() => {
          logout();
          navigate("/");
        }}
        sx={{ marginTop: 1 }}
      >
        <Logout fontSize="medium" sx={{ marginX: 1 }} />
        {t("Logout.1")}
      </MenuItem>
      <Divider />
      <Box sx={{ marginX: 2, marginBottom: 1, marginTop: 2 }}>
        {organisation !== null ? (
          <AddProduct title="Add&nbsp;product" />
        ) : (
          <CreateOrganisation variant="black" />
        )}
      </Box>
    </Menu>
  );

  return (
    <>
      <AppBar
        elevation={0}
        sx={{ borderBottom: "1px solid #dfe0df", py: "2em" }}
      >
        <Toolbar>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-around"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <Box sx={{ display: { xs: "inline-block", lg: "none" } }}>
              <Link to="/">
                <Image src={logoMobile} height={40} width={40} fit="contain" />
              </Link>
            </Box>
            <Box
              sx={{
                display: { xs: "none", lg: "inline-block" },
                marginLeft: "-16px",
              }}
            >
              <Link to="/">
                <Image src={logo} height={40} width={200} fit="contain" />
              </Link>
            </Box>

            <Stack direction="row" alignItems="center" sx={{ flexGrow: 1 }}>
              <Stack
                sx={{
                  flexGrow: 1,
                  border: "1px solid black",
                  borderRadius: "20px",
                  paddingRight: "0.5em",
                  marginX: { xs: "1rem", sm: "2rem" },
                  spacing: 1,
                }}
                justifyContent="center"
                alignItems="center"
                direction="row"
                spacing={0.1}
              >
                <form onSubmit={submitSearch} style={{ width: "100%" }}>
                  <Search direction="row-reverse" sx={{ padding: "10px" }}>
                    <IconButton type="submit">
                      <SearchIcon
                        color="inherit"
                        sx={{ stroke: "black", strokeWidth: 1, color: "black" }}
                      />
                    </IconButton>
                    <StyledInputBase
                      onChange={onSearchChange}
                      placeholder={t("Search products")}
                      inputProps={{ "aria-label": "search" }}
                      sx={{
                        flex: 1,
                        "& input::placeholder": {
                          color: "black",
                          opacity: 1,
                        },
                      }}
                    />
                  </Search>
                </form>
              </Stack>
            </Stack>

            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ display: { xs: "none", lg: "flex" } }}
            >
              {["recycled", "deadstock", "clean"].map((type) => (
                <LinkText key={type} href={`/search-page?type=${type}`}>{`${
                  type.charAt(0).toUpperCase() + type.slice(1)
                } fabric`}</LinkText>
              ))}
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  borderColor: "black",
                  height: "30px",
                  alignSelf: "center",
                  borderWidth: 1,
                }}
              />
              {organisation && (
                <IconButton
                  color="inherit"
                  onClick={() => navigate("notifications")}
                >
                  <Badge color="error">
                    <Mail sx={{ color: "black" }} />
                  </Badge>
                </IconButton>
              )}

              <IconButton
                color="inherit"
                onClick={() => navigate("my-favourites")}
              >
                <Favorite sx={{ color: "black" }} />
              </IconButton>

              <Tooltip title="Account settings">
                <IconButton color="inherit" onClick={handleProfileMenuOpen}>
                  <AccountCircle sx={{ color: "black" }} />
                </IconButton>
              </Tooltip>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  borderColor: "black",
                  height: "30px",
                  alignSelf: "center",
                  borderWidth: 1,
                }}
              />
              <Box sx={{ paddingLeft: 2 }}>
                {organisation !== null ? (
                  <AddProduct title="Add&nbsp;product" />
                ) : (
                  <CreateOrganisation variant="black" />
                )}
              </Box>
            </Stack>

            <Box
              sx={{
                display: { xs: "flex", lg: "none" },
                color: "primary.main",
              }}
            >
              <IconButton aria-label="show more" onClick={handleMobileMenuOpen}>
                <MoreVert sx={{ color: "black" }} />
              </IconButton>
            </Box>

            {renderMenu}
            {renderMobileMenu}
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default UserNavbar;
